import {BehaviorSubject} from 'rxjs';
import {Observable} from 'rxjs';
import {RestService} from './rest.service';
import {environment} from '../global/app';
import {map} from 'rxjs/operators';
import { IAuthResponse} from '../interfaces/auth-response';
import {StorageService} from './storage.service';
import {Credentials, ICredentials} from '../interfaces/credentials';
import {AccountService} from "./account.service";
import {IAuthReset} from "../interfaces/auth";

class AuthServiceController extends RestService {

  public validationMessages = {
    coupon: {
      required: 'Coupon ist notwendig.',
      string: 'Coupon muss aus Buchstaben und Zahlen bestehen',
      notvalid: 'Ungültiger Coupon. Prüfe Sie die Eingabe'
    }
  };


  private isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoggedIn = this.isLoggedIn$.asObservable();
  private credentials$: BehaviorSubject<ICredentials> = new BehaviorSubject<ICredentials>(
    new Credentials().deserialize({token: null, resource: null}
    ));
  public credentials = this.credentials$.asObservable();

  constructor() {
    super();
    const credentials = new Credentials().deserialize(StorageService.get('credentials'));
    this.setApi(environment.REST_API);
    this.credentials$.next(credentials);
    if (credentials
      && credentials.hasOwnProperty('token')
      && credentials.accessToken !== null) {
      this.isLoggedIn$.next(true);
    } else {
      this.isLoggedIn$.next(false);
    }
  }

  public setValidationMessages(validationMessages){
    this.validationMessages = validationMessages;
  }

  public isLoggedInValue() {
    return this.isLoggedIn$.getValue();
  }
  public resetPassword(identifier: string,url:string): Observable<any> {
    return this.create('/reset-password', {email:identifier,url:url}).pipe(
        map((response: IAuthResponse) => {
          this.setCredentials(response);
          return response;
        })
    );
  }

  public changePassword(reset: IAuthReset): Observable<any> {
    const data = Object.assign(reset)
    return this.create(`/set-password`, data).pipe(
        map((response: IAuthResponse) => {
          this.setCredentials(response);
          return response;
        })
    );
  }

  public login(coupon: string): Observable<IAuthResponse> {
    return this.create('/auth/login', {coupon, locale: 'de',type:'coupon'}).pipe(
      map((response: IAuthResponse) => {
        this.setCredentials(response);
        return response;
      })
    );
  }
  public userLogin(data:any): Observable<IAuthResponse> {
    let email = data.email;
    let password = data.password;
    return this.create('/auth/login', {password,email, locale: 'de',type:'login'}).pipe(
        map((response: IAuthResponse) => {
          this.setCredentials(response);
          return response;
        })
    );
  }



  public isAdmin() {
    return AccountService.hasRole("admin")
  }

  public logout() {
   this.removeCredentials();
   StorageService.set('account', null);
   StorageService.set('coupon', null);
  }

  private setCredentials(auth: IAuthResponse) {
    if (auth.hasOwnProperty('coupon')) {
      StorageService.set("coupon", auth.coupon);
    }
    const credentials: ICredentials = new Credentials().deserialize(auth);
    this.setAuthHeader(credentials);
    StorageService.set('credentials', credentials);
    AccountService.set(auth.user);
    this.isLoggedIn$.next(true);
  }

  public removeCredentials() {
    this.credentials$.next(null);
    StorageService.set('credentials', null);
    this.setAuthHeader(null);
    this.isLoggedIn$.next(false);
  }


}

export const AuthService = new AuthServiceController();